import { Storage } from '@ionic/storage';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ArrayUtil } from '../shared/utils/array-util';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
export class HourStorageService {
    constructor(storage) {
        this.storage = storage;
    }
    get() {
        // console.log('load hours');
        return from(this.storage.get('hours'))
            .pipe(map(hours => hours ? hours : []));
    }
    save(hours) {
        // console.log('save hours');
        return from(this.storage.set('hours', hours))
            .pipe(map(_ => hours));
    }
    update(updatedHours) {
        // console.log('update hours');
        return this.get()
            .pipe(switchMap(savedHours => {
            updatedHours.forEach(hour => {
                savedHours = this.updateHour(hour, savedHours);
            });
            return this.save(savedHours);
        }));
    }
    remove(hours) {
        // console.log('remove hours');
        return this.get()
            .pipe(switchMap((savedHours) => {
            const existHour = hour => hours.findIndex(h => h.projectTaskHourId === hour.projectTaskHourId) !== -1;
            // sent hours can remove from storage array
            const newHours = savedHours.filter(savedHour => !existHour(savedHour));
            return this.save(newHours);
        }));
    }
    updateHour(hour, hours) {
        const index = hours.findIndex(h => h.projectTaskHourId === hour.projectTaskHourId);
        if (index === -1) {
            hours.unshift(hour);
        }
        else {
            hours = ArrayUtil.replaceAt(hours, index, hour);
        }
        return hours;
    }
}
HourStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HourStorageService_Factory() { return new HourStorageService(i0.ɵɵinject(i1.Storage)); }, token: HourStorageService, providedIn: "root" });
