export class PromiseUtil {

    // Measure executin of a promsie
    // Source: https://stackoverflow.com/a/44158570/2419799
    static measureExecution(fn: () => Promise<any>): Promise<number> {
        const onPromiseDone = () => performance.now() - start;

        const start = performance.now();
        return fn().then(onPromiseDone, onPromiseDone);
    }

}
