import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Hour } from '../types/hour';
import { ArrayUtil } from '../shared/utils/array-util';

@Injectable({
  providedIn: 'root'
})
export class HourStorageService {

  constructor(
    private storage: Storage,
  ) { }


  get(): Observable<Hour[]> {
    // console.log('load hours');
    return from(this.storage.get('hours'))
      .pipe(
        map(hours => hours ? hours : []),
      );
  }

  save(hours: Hour[]): Observable<Hour[]> {
    // console.log('save hours');
    return from(this.storage.set('hours', hours))
      .pipe(
        map(_ => hours), // return saved hours
      );
  }

  update(updatedHours: Hour[]): Observable<Hour[]> {
    // console.log('update hours');
    return this.get()
      .pipe(
        switchMap(savedHours => {
          updatedHours.forEach(hour => {
            savedHours = this.updateHour(hour, savedHours);
          });
          return this.save(savedHours);
        }),
      );
  }

  remove(hours: Hour[]): Observable<Hour[]> {
    // console.log('remove hours');
    return this.get()
      .pipe(
        switchMap((savedHours: Hour[]) => {
          const existHour = hour => hours.findIndex(h => h.projectTaskHourId === hour.projectTaskHourId) !== -1;
          // sent hours can remove from storage array
          const newHours = savedHours.filter(savedHour => !existHour(savedHour));
          return this.save(newHours);
        }),
      );
  }

  private updateHour(hour: Hour, hours: Hour[]): Hour[] {
    const index = hours.findIndex(h => h.projectTaskHourId === hour.projectTaskHourId);
    if (index === -1) {
      hours.unshift(hour);
    } else {
      hours = ArrayUtil.replaceAt(hours, index, hour);
    }
    return hours;
  }

}
