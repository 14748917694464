import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  private isAuthenticated: boolean;

  constructor(
    private authService: AuthService,
  ) {
    this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ): Observable<boolean> {
    return this.authService.isDoneLoading$
      .pipe(
        filter(isDone => isDone),
        // tap(() => console.log('loggedin guard: ', this.isAuthenticated)),
        tap(() => this.isAuthenticated || this.authService.login(state.url)), // Force Loging wenn nicht
        map(() => this.isAuthenticated),
      );
  }

}
