import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileLoadedGuard implements CanActivate {
  // private isAuthenticated: boolean;

  constructor(
    private authService: AuthService,
  ) {
    // this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isProfileLoaded$();
    // .pipe(tap(loaded => console.log({ loaded })));

    // return this.authService.isUserProfileLoaded$
    //   .pipe(
    //     filter(isLoaded => isLoaded),
    //     tap(() => {
    //       const hasAccess = this.isAuthenticated && this.authService.isInRole(allowedRoles);
    //       if (!hasAccess) {
    //         this.authService.navigateToUnauthorized(state.url);
    //       }
    //     }),
    //   );
  }

  private isProfileLoaded$(): Observable<boolean> {
    const isAuthLoaded$ = this.authService.isDoneLoading$.pipe(filter((done) => done));
    // const isAuth$ = this.authService.isAuthenticated$;
    const isProfileLoaded$ = this.authService.isUserProfileLoaded$.pipe(filter((done) => done));

    return isAuthLoaded$
      .pipe(
        switchMap(() => isProfileLoaded$),
        // switchMap(() => isAuth$),
        // switchMap((isAuth) => {
        //   if (!isAuth) {
        //     return of(false);
        //   }
        //   return isProfileLoaded$
        //     .pipe(
        //       map(() => this.authService.isAuthorized(userId)),
        //     );
        // }),
      );
  }

}
