import { registerLocaleData } from '@angular/common';
import { NgModule, Optional, SkipSelf, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import localeDeCH from '@angular/common/locales/de-CH';

import { LogModule, LogInterceptor, LogErrorHandler } from '@viag/ngx-logger';
import { TranslocoRootModule } from './transloco-root.module';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';

import { authConfig } from './auth/auth-config';
import { authModuleConfig } from './auth/auth-module-config';

import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

// CoreModule with providers for the singleton services you load when the application starts.
// Do gather application-wide, single use components (e.g. NavComponent and SpinnerComponent) in the CoreModule.
// Import it once (in the AppModule) when the app starts and never import it anywhere else.
@NgModule({
  imports: [
    SharedModule,
    LogModule.forRoot({
      application: environment.application,
      version: environment.version,
      logUrl: environment.logUrl,
      enableConsoleLog: environment.enableConsoleLog,
      enableRemoteLog: environment.enableRemoteLog,
      logLevels: {
        signalR: environment.production ? 3 : 2,
      },
    }),
    OAuthModule.forRoot(),
    TranslocoRootModule,
  ],
  declarations: [
  ]
})
export class CoreModule {

  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
    registerLocaleData(localeDeCH);
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: ErrorHandler, useClass: LogErrorHandler },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }


}
