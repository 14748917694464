<ion-app [class.dark-theme]="dark">
  <ngx-loading-bar [color]="'#428cff'"></ngx-loading-bar>
  <ion-split-pane contentId="main-content" *transloco="let t">

    <ion-menu contentId="main-content">
      <ion-content>
        <ion-list lines="none">
          <ion-list-header>
            {{ t('app') }}
          </ion-list-header>
          <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{ t(p.title) }}
              </ion-label>
            </ion-item>

          </ion-menu-toggle>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header>
            Konto
          </ion-list-header>

          <ion-item>
            <ion-icon slot="start" name="person-outline"></ion-icon>
            <ion-label>
              {{ userName }}
            </ion-label>
          </ion-item>

          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="logout()" detail="false">
              <ion-icon slot="start" name="log-out-outline"></ion-icon>
              <ion-label>
                Abmelden
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item>
              <ion-icon slot="start" name="moon-outline"></ion-icon>
              <ion-label>
                Dark Mode
              </ion-label>
              <ion-toggle [checked]="dark" (ionChange)="setDark($event.target.checked)"></ion-toggle>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>

        <ion-list lines="none">
          <ion-list-header>
            Info
          </ion-list-header>
          <ion-item>
            <ion-label>
              <small>{{ t('version', { version: version }) }}</small>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <small>Last refresh: {{ resumed | date : 'HH:mm' }}</small>
            </ion-label>
            <ion-label>
              <small>Not Synced: {{hourCount}}</small>
            </ion-label>
          </ion-item>
          <ion-item>
            <a href="https://www.viag.ch" target="_blank">
              <img src="./assets/img/viag-logo.png" alt="viag Müller Informatik" />
            </a>
          </ion-item>
          <ion-item>
            <ion-label>
              <span class="copyright">© viag Müller Informatik 2019-{{currentYear}}</span>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>
