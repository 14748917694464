import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "@angular/router";
export class CheckTutorial {
    constructor(storage, router) {
        this.storage = storage;
        this.router = router;
    }
    canLoad() {
        return this.storage.get('ion_did_tutorial').then(res => {
            if (res) {
                this.router.navigate(['/app', 'tabs', 'schedule']);
                return false;
            }
            else {
                return true;
            }
        });
    }
}
CheckTutorial.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckTutorial_Factory() { return new CheckTutorial(i0.ɵɵinject(i1.Storage), i0.ɵɵinject(i2.Router)); }, token: CheckTutorial, providedIn: "root" });
