import { filter } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../core/auth/auth.service";
import * as i2 from "angular-oauth2-oidc";
export class ProfileService {
    constructor(authService, oAuthModuleConfig) {
        this.authService = authService;
        this.oAuthModuleConfig = oAuthModuleConfig;
        this.apiUrl$ = new ReplaySubject(1);
        this.authService.claims$.pipe(filter(claims => claims)).subscribe(claims => {
            const apiUrl = environment.production
                ? claims.apiUrl
                : environment.apiUrl;
            // Send AccessToken in Bearer Token to api
            this.oAuthModuleConfig.resourceServer.allowedUrls = [apiUrl];
            // console.log(this.oAuthModuleConfig.resourceServer.allowedUrls);
            this.apiUrl$.next(apiUrl);
        });
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.OAuthModuleConfig)); }, token: ProfileService, providedIn: "root" });
