import { Routes } from '@angular/router';
import { LoggedInGuard } from './core/auth/guards/logged-in.guard';
import { ProfileLoadedGuard } from './core/auth/guards/profile-loaded.guard';
import { CheckTutorial } from './providers/_check-tutorial.service';
const ɵ0 = () => import("./pages/project-list/project-list.module.ngfactory").then(m => m.ProjectListModuleNgFactory), ɵ1 = () => import("./pages/project/project.module.ngfactory").then(m => m.ProjectPageModuleNgFactory), ɵ2 = () => import("./pages/hour-list/hour-list.module.ngfactory").then(m => m.HourListPageModuleNgFactory), ɵ3 = () => import("./pages/account/account.module.ngfactory").then(m => m.AccountModuleNgFactory), ɵ4 = () => import("./pages/support/support.module.ngfactory").then(m => m.SupportModuleNgFactory), ɵ5 = () => import("./pages/login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ6 = () => import("./pages/signup/signup.module.ngfactory").then(m => m.SignUpModuleNgFactory), ɵ7 = () => import("./pages/tabs-page/tabs-page.module.ngfactory").then(m => m.TabsModuleNgFactory), ɵ8 = () => import("./pages/tutorial/tutorial.module.ngfactory").then(m => m.TutorialModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: '/projects',
        pathMatch: 'full'
    },
    {
        path: 'projects',
        canActivate: [LoggedInGuard, ProfileLoadedGuard],
        children: [
            {
                path: '',
                loadChildren: ɵ0,
            },
            {
                path: ':projectTaskId',
                loadChildren: ɵ1,
            }
        ]
    },
    {
        path: 'hours',
        canActivate: [LoggedInGuard],
        children: [
            {
                path: '',
                loadChildren: ɵ2,
            },
        ],
    },
    {
        path: 'account',
        loadChildren: ɵ3
    },
    {
        path: 'support',
        loadChildren: ɵ4
    },
    {
        path: 'login',
        loadChildren: ɵ5
    },
    {
        path: 'signup',
        loadChildren: ɵ6
    },
    {
        path: 'app',
        canActivate: [LoggedInGuard],
        loadChildren: ɵ7
    },
    {
        path: 'tutorial',
        loadChildren: ɵ8,
        canLoad: [CheckTutorial]
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
