import { BehaviorSubject, of } from 'rxjs';
import { switchMap, filter, tap, retry, catchError, map } from 'rxjs/operators';
import { LogService } from '@viag/ngx-logger';
import { HourApiService } from './hour-api.service';
import { HourStorageService } from './hour-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./hour-api.service";
import * as i2 from "./hour-storage.service";
import * as i3 from "@viag/ngx-logger";
export class HourService {
    constructor(api, storage, log) {
        this.api = api;
        this.storage = storage;
        this.log = log;
        this.hoursSubject$ = new BehaviorSubject([]);
        this.hours$ = this.hoursSubject$.asObservable();
        this.loadHours();
    }
    // TODO: type Hour
    save(updatedHours) {
        this.log.debug('save hours', updatedHours);
        return this.storage.update(updatedHours)
            .pipe(tap(hours => this.log.debug('saved hours', hours)), tap(hours => this.hoursSubject$.next(hours)), switchMap(hours => this.sendHours(hours)));
    }
    send() {
        return this.storage.get()
            .pipe(tap(hours => this.log.debug(`need sync: ${hours && hours.length > 0}`)), filter(hours => hours && hours.length > 0), switchMap(hours => this.sendHours(hours)));
    }
    getByTaskId(projectTaskId) {
        return this.api.getByTaskId(projectTaskId).pipe(retry(2), catchError((error) => {
            this.log.info('error catched, return from cache', error);
            return this.storage.get()
                .pipe(map(hours => hours.filter(h => h.projectTaskId === projectTaskId)));
        }));
    }
    sendHours(hours) {
        return this.api.postSequential(hours)
            .pipe(tap(_ => this.log.debug('remove hours', hours)), switchMap(() => this.storage.remove(hours)), tap(newHours => this.hoursSubject$.next(newHours)), map(_ => hours), // Nicht das neue Array zurückgeben sondern die gesendeten Stunden
        catchError(err => {
            this.log.error(err); // z.B. Server down oder kein Net
            return of(hours);
        }));
    }
    loadHours() {
        this.storage.get().subscribe(hours => {
            this.hoursSubject$.next(hours);
        });
    }
}
HourService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HourService_Factory() { return new HourService(i0.ɵɵinject(i1.HourApiService), i0.ɵɵinject(i2.HourStorageService), i0.ɵɵinject(i3.LogService)); }, token: HourService, providedIn: "root" });
