export class ArrayUtil {

    // Replace element in array at index, functional programming way (immutable array)
    static replaceAt(array, index, value) {
        const ret = array.slice(0);
        ret[index] = value;
        return ret;
    }

}
