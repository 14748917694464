import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import lifecycle from 'page-lifecycle';

import { LogService, RemoteLogService } from '@viag/ngx-logger';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(this.oauthService.hasValidIdToken());
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged());

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private isUserProfileLoadedSubject$ = new BehaviorSubject<boolean>(false);
  public isUserProfileLoaded$ = this.isUserProfileLoadedSubject$.asObservable();

  private claimsSubject$ = new BehaviorSubject<any>(null);
  public claims$ = this.claimsSubject$.asObservable();

  // private serviceTimeout = false; // Offline oder identity server down!

  public navigateToUnauthorized(stateUrl?: string) {
    // Nicht to have: Remember current URL
    this.router.navigateByUrl('/unauthorized');
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private log: LogService,
    private remoteLog: RemoteLogService,
  ) {
    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        this.log.error('oauth', event);
      } else {
        this.log.debug('oauth', event);
      }
    });

    // Benutzername für RemoteLog anreichern
    // Eigentlich mit factory-Funktion APP_INITIALIZER in CoreModule lösen
    // aber AuthService-Instanz ist vom Injector immer undefined
    // Problem: Sobald im AuthService die Router-Injizierung im Constructor auskommentiert wird,
    // wird der AuthService in der Factory-Funktion korrekt übergeben, k.A. wieso
    this.claims$.pipe(
      filter(claims => claims),
      map(claims => claims.name),
      filter(userName => userName),
    ).subscribe(userName => this.remoteLog.enrich({ user: userName }));

    this.oauthService.events
      .subscribe(event => {

        const offlineErrors = [
          'discovery_document_load_error',
          'token_refresh_error',
          'silent_refresh_error'
        ]; // offline oder id-server not avialble
        const isOfflineError = offlineErrors.indexOf(event.type) >= 0;

        if (isOfflineError) {
          this.log.debug(`No validation IdToken for oauth-event ${event.type}!`);
          this.isAuthenticatedSubject$.next(true);
          this.CheckUserProfileLoaded();
          return;
        }
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidIdToken());

        if (event instanceof OAuthErrorEvent) {
          const error = (event as OAuthErrorEvent);
          // after logout in identityserver and then reopen the app
          const needLogin = error.type === 'code_error' && (error.params as any).error === 'login_required';
          if (needLogin) {
            this.log.debug('Need Login?', { needLogin });
            this.login();
          }
        }
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['user_profile_loaded'].includes(e.type)))
      .subscribe(() => {
        this.claimsSubject$.next(this.oauthService.getIdentityClaims());
        this.isUserProfileLoadedSubject$.next(true);
      });

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToUnauthorized());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  // Inspired by: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards
  public runInitialLogin(): Promise<void> {
    if (location.hash) {
      this.log.info('Encountered hash fragment');
      // console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    this.log.debug('vor discovery');

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()
      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => {
        // this.serviceTimeout = false;
        this.log.debug('after discovery');
        this.oauthService.tryLogin();
      })

      .then(() => {
        this.log.debug('hasValidIdToken?: ' + this.oauthService.hasValidIdToken());
        if (this.oauthService.hasValidIdToken()) {
          return Promise.resolve();
        }

        this.log.debug('try silent refresh because no valid it token ');

        // 2. SILENT LOGIN:
        // Try to log in via silent refresh because the IdServer
        // might have a cookie to remember the user, so we can
        // prevent doing a redirect:
        return this.oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch(result => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.oauthService.initImplicitFlow();
              // return Promise.resolve();
              //
              // Instead, we'll now do this:
              this.log.debug('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          // fix: IdentityServer encoding the state url
          // https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/ +
          // commit/57a67f3a8ad5a9715709087af2975101c3f74d28#diff-de9875bb847e4472f78dd9f95fcac83a
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          // this.log.debug(`There was state of ${this.oauthService.state}, so we are redirect to: ${stateUrl}`);
          // Workarround mit Timeout sonst funktioniert navigateByUrl nicht
          // Issue im August 2019 immernoch offen: https://github.com/angular/angular/issues/17957
          setTimeout(() => {
            this.router.navigateByUrl(stateUrl);
          }, 100);
        } else {
          this.CheckUserProfileLoaded();
        }
        // this.log.debug('Observe PageLifecyle active event');
        lifecycle.addEventListener('statechange', (event) => {
          // use active state for unfrozen from mobile browsers
          if (event.newState === 'active') {
            // this.log.debug('PageLifecyle active event');
            if (!this.oauthService.hasValidIdToken()) {
              this.oauthService.silentRefresh();
            }
          }
        });
      })
      .catch((result) => {
        // this.log.debug('catch discovery', result);
        // this.log.debug('has still valid token? ' + this.oauthService.hasValidIdToken());
        if (result.status === 504) { // Gateway timeout
          // this.serviceTimeout = true;
        }
        this.isDoneLoadingSubject$.next(true);
      });
  }

  private CheckUserProfileLoaded() {
    const claims: any = this.oauthService.getIdentityClaims();
    this.claimsSubject$.next(claims);
    if (claims && claims.name) { // check for name, because it's only availble from profile
      this.isUserProfileLoadedSubject$.next(true);
    }
  }

  public login(targetUrl?: string) {
    this.log.info('user login');
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public isInRole(allowedRoles: string[] | string): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    const expectedRoles = Array.isArray(allowedRoles)
      ? allowedRoles
      : [allowedRoles];
    const actualRoles = Array.isArray(claims.role)
      ? claims.role
      : [claims.role];

    const hasAccess = this.hasRoleAccess(expectedRoles, actualRoles);
    return hasAccess;
  }

  private hasRoleAccess(expectedRoles: string[], actualRoles: string[]): boolean {
    return expectedRoles.some(r => actualRoles.includes(r));
  }


  public get userName() {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims
      ? claims.name
      : null;
  }

  public get userId() {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims
      ? claims.sub
      : null;
  }

  public get personId() {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims
      ? claims.personId
      : null;
  }

  public get clubId() {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims
      ? claims.clubId
      : null;
  }

  public get homeLocation() {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims
      ? Array.isArray(claims.home_location)
        ? claims.home_location[0]
        : claims.home_location
      : null;
  }

  // Returns TRUE if the user is berechtigt, FALSE otherwise.
  public isAuthorized(userId: any) {
    const myUserId = this.userId;
    const isMy = myUserId === userId;
    const isAdmin = this.isInRole('admin');
    return isMy || isAdmin;
  }

  public logout() {
    this.log.info('user logout');
    this.oauthService.logOut();
  }
  public refresh() { this.oauthService.silentRefresh(); }

  public convertTimestamp(timestamp: number): Date {
    const date = new Date(0);
    date.setUTCSeconds(timestamp);
    return date;
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  // public get accessToken() { return this.oauthService.getAccessToken(); }
  // public get refreshToken() { return this.oauthService.getRefreshToken(); }
  // public get identityClaims() { return this.oauthService.getIdentityClaims(); }
  // public get idToken() { return this.oauthService.getIdToken(); }
  // public get logoutUrl() { return this.oauthService.logoutUrl; }
}
