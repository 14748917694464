import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslocoModule } from '@ngneat/transloco';
import { GmapPipe } from './gmap.pipe';
import { DayPipe } from './day.pipe';
import { DurationPipe } from './duration.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslocoModule,
  ],
  declarations: [
    GmapPipe,
    DayPipe,
    DurationPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslocoModule,
    GmapPipe,
    DayPipe,
    DurationPipe,
  ],
})
export class SharedModule { }
