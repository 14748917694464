import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { switchMap, filter, tap, retry, catchError, map } from 'rxjs/operators';
import { LogService } from '@viag/ngx-logger';

import { Hour } from '../types/hour';
import { HourApiService } from './hour-api.service';
import { HourStorageService } from './hour-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HourService {

  constructor(
    private api: HourApiService,
    private storage: HourStorageService,
    private log: LogService,
  ) {
    this.loadHours();
  }

  private hoursSubject$ = new BehaviorSubject<Hour[]>([]);
  public hours$ = this.hoursSubject$.asObservable();

  // TODO: type Hour
  save(updatedHours: any[]): Observable<Hour[]> {
    this.log.debug('save hours', updatedHours);
    return this.storage.update(updatedHours)
      .pipe(
        tap(hours => this.log.debug('saved hours', hours)),
        tap(hours => this.hoursSubject$.next(hours)),
        switchMap(hours => this.sendHours(hours)),
      );
  }

  send(): Observable<Hour[]> {
    return this.storage.get()
      .pipe(
        tap(hours => this.log.debug(`need sync: ${hours && hours.length > 0}`)),
        filter(hours => hours && hours.length > 0),
        switchMap(hours => this.sendHours(hours)),
      );
  }

  getByTaskId(projectTaskId: string): Observable<Hour[]> {
    return this.api.getByTaskId(projectTaskId).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        this.log.info('error catched, return from cache', error);
        return this.storage.get()
          .pipe(
            map(hours => hours.filter(h => h.projectTaskId === projectTaskId)),
          );
      })
    );
  }

  private sendHours(hours: Hour[]): Observable<Hour[]> {
    return this.api.postSequential(hours)
      .pipe(
        tap(_ => this.log.debug('remove hours', hours)),
        switchMap(() => this.storage.remove(hours)),
        tap(newHours => this.hoursSubject$.next(newHours)),
        map(_ => hours), // Nicht das neue Array zurückgeben sondern die gesendeten Stunden
        catchError(err => {
          this.log.error(err); // z.B. Server down oder kein Net
          return of(hours);
        }),
      );
  }

  private loadHours() {
    this.storage.get().subscribe(hours => {
      this.hoursSubject$.next(hours);
    });
  }

}
