import { Router } from '@angular/router';
import lifecycle from 'page-lifecycle';
import { LogService, RemoteLogService } from '@viag/ngx-logger';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
import * as i2 from "@angular/router";
import * as i3 from "@viag/ngx-logger";
export class AuthService {
    constructor(oauthService, router, log, remoteLog) {
        this.oauthService = oauthService;
        this.router = router;
        this.log = log;
        this.remoteLog = remoteLog;
        this.isAuthenticatedSubject$ = new BehaviorSubject(this.oauthService.hasValidIdToken());
        this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged());
        this.isDoneLoadingSubject$ = new ReplaySubject();
        this.isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
        this.isUserProfileLoadedSubject$ = new BehaviorSubject(false);
        this.isUserProfileLoaded$ = this.isUserProfileLoadedSubject$.asObservable();
        this.claimsSubject$ = new BehaviorSubject(null);
        this.claims$ = this.claimsSubject$.asObservable();
        // Useful for debugging:
        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                this.log.error('oauth', event);
            }
            else {
                this.log.debug('oauth', event);
            }
        });
        // Benutzername für RemoteLog anreichern
        // Eigentlich mit factory-Funktion APP_INITIALIZER in CoreModule lösen
        // aber AuthService-Instanz ist vom Injector immer undefined
        // Problem: Sobald im AuthService die Router-Injizierung im Constructor auskommentiert wird,
        // wird der AuthService in der Factory-Funktion korrekt übergeben, k.A. wieso
        this.claims$.pipe(filter(claims => claims), map(claims => claims.name), filter(userName => userName)).subscribe(userName => this.remoteLog.enrich({ user: userName }));
        this.oauthService.events
            .subscribe(event => {
            const offlineErrors = [
                'discovery_document_load_error',
                'token_refresh_error',
                'silent_refresh_error'
            ]; // offline oder id-server not avialble
            const isOfflineError = offlineErrors.indexOf(event.type) >= 0;
            if (isOfflineError) {
                this.log.debug(`No validation IdToken for oauth-event ${event.type}!`);
                this.isAuthenticatedSubject$.next(true);
                this.CheckUserProfileLoaded();
                return;
            }
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidIdToken());
            if (event instanceof OAuthErrorEvent) {
                const error = event;
                // after logout in identityserver and then reopen the app
                const needLogin = error.type === 'code_error' && error.params.error === 'login_required';
                if (needLogin) {
                    this.log.debug('Need Login?', { needLogin });
                    this.login();
                }
            }
        });
        this.oauthService.events
            .pipe(filter(e => ['token_received'].includes(e.type)))
            .subscribe(e => this.oauthService.loadUserProfile());
        this.oauthService.events
            .pipe(filter(e => ['user_profile_loaded'].includes(e.type)))
            .subscribe(() => {
            this.claimsSubject$.next(this.oauthService.getIdentityClaims());
            this.isUserProfileLoadedSubject$.next(true);
        });
        this.oauthService.events
            .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
            .subscribe(e => this.navigateToUnauthorized());
        this.oauthService.setupAutomaticSilentRefresh();
    }
    // private serviceTimeout = false; // Offline oder identity server down!
    navigateToUnauthorized(stateUrl) {
        // Nicht to have: Remember current URL
        this.router.navigateByUrl('/unauthorized');
    }
    // Inspired by: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards
    runInitialLogin() {
        if (location.hash) {
            this.log.info('Encountered hash fragment');
            // console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
        }
        this.log.debug('vor discovery');
        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        return this.oauthService.loadDiscoveryDocument()
            // 1. HASH LOGIN:
            // Try to log in via hash fragment after redirect back
            // from IdServer from initImplicitFlow:
            .then(() => {
            // this.serviceTimeout = false;
            this.log.debug('after discovery');
            this.oauthService.tryLogin();
        })
            .then(() => {
            this.log.debug('hasValidIdToken?: ' + this.oauthService.hasValidIdToken());
            if (this.oauthService.hasValidIdToken()) {
                return Promise.resolve();
            }
            this.log.debug('try silent refresh because no valid it token ');
            // 2. SILENT LOGIN:
            // Try to log in via silent refresh because the IdServer
            // might have a cookie to remember the user, so we can
            // prevent doing a redirect:
            return this.oauthService.silentRefresh()
                .then(() => Promise.resolve())
                .catch(result => {
                // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                // Only the ones where it's reasonably sure that sending the
                // user to the IdServer will help.
                const errorResponsesRequiringUserInteraction = [
                    'interaction_required',
                    'login_required',
                    'account_selection_required',
                    'consent_required',
                ];
                if (result
                    && result.reason
                    && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
                    // 3. ASK FOR LOGIN:
                    // At this point we know for sure that we have to ask the
                    // user to log in, so we redirect them to the IdServer to
                    // enter credentials.
                    //
                    // Enable this to ALWAYS force a user to login.
                    // this.oauthService.initImplicitFlow();
                    // return Promise.resolve();
                    //
                    // Instead, we'll now do this:
                    this.log.debug('User interaction is needed to log in, we will wait for the user to manually log in.');
                    return Promise.resolve();
                }
                // We can't handle the truth, just pass on the problem to the
                // next handler.
                return Promise.reject(result);
            });
        })
            .then(() => {
            this.isDoneLoadingSubject$.next(true);
            // Check for the strings 'undefined' and 'null' just to be sure. Our current
            // login(...) should never have this, but in case someone ever calls
            // initImplicitFlow(undefined | null) this could happen.
            if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                let stateUrl = this.oauthService.state;
                // fix: IdentityServer encoding the state url
                // https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/ +
                // commit/57a67f3a8ad5a9715709087af2975101c3f74d28#diff-de9875bb847e4472f78dd9f95fcac83a
                if (stateUrl.startsWith('/') === false) {
                    stateUrl = decodeURIComponent(stateUrl);
                }
                // this.log.debug(`There was state of ${this.oauthService.state}, so we are redirect to: ${stateUrl}`);
                // Workarround mit Timeout sonst funktioniert navigateByUrl nicht
                // Issue im August 2019 immernoch offen: https://github.com/angular/angular/issues/17957
                setTimeout(() => {
                    this.router.navigateByUrl(stateUrl);
                }, 100);
            }
            else {
                this.CheckUserProfileLoaded();
            }
            // this.log.debug('Observe PageLifecyle active event');
            lifecycle.addEventListener('statechange', (event) => {
                // use active state for unfrozen from mobile browsers
                if (event.newState === 'active') {
                    // this.log.debug('PageLifecyle active event');
                    if (!this.oauthService.hasValidIdToken()) {
                        this.oauthService.silentRefresh();
                    }
                }
            });
        })
            .catch((result) => {
            // this.log.debug('catch discovery', result);
            // this.log.debug('has still valid token? ' + this.oauthService.hasValidIdToken());
            if (result.status === 504) { // Gateway timeout
                // this.serviceTimeout = true;
            }
            this.isDoneLoadingSubject$.next(true);
        });
    }
    CheckUserProfileLoaded() {
        const claims = this.oauthService.getIdentityClaims();
        this.claimsSubject$.next(claims);
        if (claims && claims.name) { // check for name, because it's only availble from profile
            this.isUserProfileLoadedSubject$.next(true);
        }
    }
    login(targetUrl) {
        this.log.info('user login');
        this.oauthService.initLoginFlow(targetUrl || this.router.url);
    }
    isInRole(allowedRoles) {
        const claims = this.oauthService.getIdentityClaims();
        const expectedRoles = Array.isArray(allowedRoles)
            ? allowedRoles
            : [allowedRoles];
        const actualRoles = Array.isArray(claims.role)
            ? claims.role
            : [claims.role];
        const hasAccess = this.hasRoleAccess(expectedRoles, actualRoles);
        return hasAccess;
    }
    hasRoleAccess(expectedRoles, actualRoles) {
        return expectedRoles.some(r => actualRoles.includes(r));
    }
    get userName() {
        const claims = this.oauthService.getIdentityClaims();
        return claims
            ? claims.name
            : null;
    }
    get userId() {
        const claims = this.oauthService.getIdentityClaims();
        return claims
            ? claims.sub
            : null;
    }
    get personId() {
        const claims = this.oauthService.getIdentityClaims();
        return claims
            ? claims.personId
            : null;
    }
    get clubId() {
        const claims = this.oauthService.getIdentityClaims();
        return claims
            ? claims.clubId
            : null;
    }
    get homeLocation() {
        const claims = this.oauthService.getIdentityClaims();
        return claims
            ? Array.isArray(claims.home_location)
                ? claims.home_location[0]
                : claims.home_location
            : null;
    }
    // Returns TRUE if the user is berechtigt, FALSE otherwise.
    isAuthorized(userId) {
        const myUserId = this.userId;
        const isMy = myUserId === userId;
        const isAdmin = this.isInRole('admin');
        return isMy || isAdmin;
    }
    logout() {
        this.log.info('user logout');
        this.oauthService.logOut();
    }
    refresh() { this.oauthService.silentRefresh(); }
    convertTimestamp(timestamp) {
        const date = new Date(0);
        date.setUTCSeconds(timestamp);
        return date;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LogService), i0.ɵɵinject(i3.RemoteLogService)); }, token: AuthService, providedIn: "root" });
