import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoggedInGuard } from './core/auth/guards/logged-in.guard';
import { ProfileLoadedGuard } from './core/auth/guards/profile-loaded.guard';
import { CheckTutorial } from './providers/_check-tutorial.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'projects',
    canActivate: [LoggedInGuard, ProfileLoadedGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/project-list/project-list.module').then(m => m.ProjectListModule),
      },
      {
        path: ':projectTaskId',
        loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectPageModule),
      }
    ]
  },
  {
    path: 'hours',
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/hour-list/hour-list.module').then(m => m.HourListPageModule),
      },
      // {
      //   path: ':date',
      //   loadChildren: () => import('./pages/hour/hour.module').then(m => m.HourPageModule),
      // }
    ],
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
