import { LogService } from '@viag/ngx-logger';
import { PromiseUtil } from '../shared/utils/promise-util';
import * as i0 from "@angular/core";
import * as i1 from "@viag/ngx-logger";
export class GpsService {
    constructor(log) {
        this.log = log;
    }
    askPermission() {
        this.getCoords()
            .then(_ => _) // value not needed
            .catch(_ => _); // just catch & forget
    }
    getCoords() {
        return new Promise(resolve => {
            PromiseUtil
                .measureExecution(() => this.getLocation().then(resolve))
                .then(duration => {
                // TODO default log as info
                // BUT if more then 1 or 2 secs then log as warn
                this.log.warn(`GPS localization`, { duration });
            });
        });
    }
    getLocation() {
        return new Promise((resolve) => {
            if (!navigator.geolocation) {
                const errorReason = 'GPS_NOT_SUPPORTED';
                this.log.error(errorReason);
                resolve(errorReason);
                return;
            }
            navigator.geolocation.getCurrentPosition(position => {
                // Format [START-POSITION/END-POSITION]
                // LAT:46.9922744890821 LON:8.30748737769473/LAT:46.9919038439502 LON:8.3076002715196
                const gpsData = `LAT:${position.coords.latitude} LON:${position.coords.longitude}`;
                resolve(gpsData);
                return;
            }, error => {
                const errorReason = this.locationError(error);
                this.log.error(errorReason, error);
                resolve(errorReason);
                return;
            }, {
                enableHighAccuracy: true,
                maximumAge: 30000,
                // Bei Android-Geräten => Einstellungen => Standort => Google Standortgenuigkeit => Aus
                // und Timeout ist Standardmässig auf Infitiy dann wir das Promise nie aufgelöst bzw. keine Callbacks werden aufgerufen
                timeout: 10000,
            });
        });
    }
    locationError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                return 'GPS_PERMISSION_DENIED';
            case error.POSITION_UNAVAILABLE:
                return 'GPS_POSITION_UNAVAILABLE';
            case error.TIMEOUT:
                return 'GPS_TIMEOUT_EXPIRED';
            case error.UNKNOWN_ERROR:
                return 'GPS_UNKNOWN_ERROR';
            default:
                return 'GPS_UNKNOWN_CODE';
        }
    }
}
GpsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GpsService_Factory() { return new GpsService(i0.ɵɵinject(i1.LogService)); }, token: GpsService, providedIn: "root" });
