import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { RemoteLogService } from '@viag/ngx-logger';
import { Guid } from '../shared/guid';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private storage: Storage,
    private remoteLogService: RemoteLogService
  ) { }

  setupDeviceId() {
    return this.storage.get('deviceId').then(deviceId => {
      if (!deviceId) {
        deviceId = Guid.uuidv4();
        this.storage.set('deviceId', deviceId);
      }
      this.remoteLogService.enrich({ deviceId });
      return deviceId;
    });
  }

}
