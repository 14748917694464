import { filter, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth.service";
export class ProfileLoadedGuard {
    // private isAuthenticated: boolean;
    constructor(authService) {
        this.authService = authService;
        // this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
    }
    canActivate(route, state) {
        return this.isProfileLoaded$();
        // .pipe(tap(loaded => console.log({ loaded })));
        // return this.authService.isUserProfileLoaded$
        //   .pipe(
        //     filter(isLoaded => isLoaded),
        //     tap(() => {
        //       const hasAccess = this.isAuthenticated && this.authService.isInRole(allowedRoles);
        //       if (!hasAccess) {
        //         this.authService.navigateToUnauthorized(state.url);
        //       }
        //     }),
        //   );
    }
    isProfileLoaded$() {
        const isAuthLoaded$ = this.authService.isDoneLoading$.pipe(filter((done) => done));
        // const isAuth$ = this.authService.isAuthenticated$;
        const isProfileLoaded$ = this.authService.isUserProfileLoaded$.pipe(filter((done) => done));
        return isAuthLoaded$
            .pipe(switchMap(() => isProfileLoaded$));
    }
}
ProfileLoadedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileLoadedGuard_Factory() { return new ProfileLoadedGuard(i0.ɵɵinject(i1.AuthService)); }, token: ProfileLoadedGuard, providedIn: "root" });
