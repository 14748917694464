import { Storage } from '@ionic/storage';
import { RemoteLogService } from '@viag/ngx-logger';
import { Guid } from '../shared/guid';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "@viag/ngx-logger";
export class DeviceService {
    constructor(storage, remoteLogService) {
        this.storage = storage;
        this.remoteLogService = remoteLogService;
    }
    setupDeviceId() {
        return this.storage.get('deviceId').then(deviceId => {
            if (!deviceId) {
                deviceId = Guid.uuidv4();
                this.storage.set('deviceId', deviceId);
            }
            this.remoteLogService.enrich({ deviceId });
            return deviceId;
        });
    }
}
DeviceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeviceService_Factory() { return new DeviceService(i0.ɵɵinject(i1.Storage), i0.ɵɵinject(i2.RemoteLogService)); }, token: DeviceService, providedIn: "root" });
