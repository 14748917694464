import { HttpClient } from '@angular/common/http';
import { map, shareReplay, concatMap } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./profile.service";
export class HourApiService {
    constructor(http, profileService) {
        this.http = http;
        this.profileService = profileService;
        this.baseUri = null;
        this.profileService.apiUrl$.subscribe(apiUrl => {
            this.baseUri = `${apiUrl}/api/projecttaskhours`;
        });
    }
    getByTaskId(projectTaskId) {
        const url = `${this.baseUri}/${projectTaskId}`;
        return this.http.get(url)
            .pipe(map((hours) => hours.map(h => {
            h.startTime = this.convertToDate(h.startTime);
            h.endTime = this.convertToDate(h.endTime);
            h.synced = true;
            return h;
        })));
    }
    /*
      Wartet bis vorheriger post fertig ist und startet erst danach!
      Problem Tabelle ProjectTaskHour hat Trigger für Projektberechnungen
      TODO: Nach Offline wenn wieder Online werden alle shareReplay-Post-Requests doch noch ausgeführt
    */
    postSequential(hours) {
        if (this.lastPost$) {
            this.lastPost$ = this.lastPost$
                .pipe(
            // tap(_ => console.log('start next post')),
            concatMap(_ => this.post(hours)), // concatMap does not subscribe to the next observable until the previous completes
            shareReplay());
        }
        else {
            this.lastPost$ = this.post(hours).pipe(shareReplay()); // shareReplay damit http.post nicht doppelt ausgeführt wird
        }
        return this.lastPost$;
    }
    post(hours) {
        const url = `${this.baseUri}/`;
        return this.http.post(url, hours);
    }
    convertToDate(date) {
        return date
            ? new Date(date)
            : null;
    }
}
HourApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HourApiService_Factory() { return new HourApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ProfileService)); }, token: HourApiService, providedIn: "root" });
