import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { filter } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AuthService } from './core/auth/auth.service';
import { DeviceService } from './providers/device.service';
import { ResumeService } from './providers/resume.service';
import { ProfileService } from './providers/profile.service';
import { HourService } from './providers/hour.service';
import { GpsService } from './providers/gps.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'projects.title',
      url: '/projects',
      icon: 'calendar-outline'
    },
    {
      title: 'hours.title',
      url: '/hours',
      icon: 'time-outline'
    },
  ];
  version;
  loggedIn = false;
  dark = false;
  resumed = null;
  userName;
  currentYear;
  hourCount = 0;

  constructor(
    private storage: Storage,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private authService: AuthService,
    private deviceService: DeviceService,
    private resumeService: ResumeService,
    private profileService: ProfileService,
    private hourService: HourService,
    private gps: GpsService,
  ) {
    // needs to be called in the constructor, because before redirectTo-rule
    this.authService.runInitialLogin();
    this.version = environment.version;
    this.resumeService.resumed$.subscribe(() => {
      this.hourService.send().subscribe();
      this.resumed = new Date();
      // TODO: check stability from appref (check out docs)
      // this.swUpdate.checkForUpdate();
    });
    this.deviceService.setupDeviceId();
    this.hourService.hours$.subscribe(hours => {
      this.hourCount = hours.length;
    });
  }

  async ngOnInit() {
    this.dark = await this.storage.get('dark');
    this.currentYear = (new Date()).getFullYear();
    this.profileService.apiUrl$.pipe(filter(apiUrl => !!apiUrl)).subscribe(_ => {
      // Direkt nach start sobald ProfileService bzw. apiUrl ready ist
      this.hourService.send().subscribe();
    });

    this.authService.claims$.subscribe(claims => {
      if (claims) {
        this.userName = claims.name;
      }
    });

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update verfügbar!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Aktualisieren'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });

    this.gps.askPermission();
  }

  logout() {
    this.authService.logout();
  }

  setDark(dark) {
    this.dark = dark;
    this.storage.set('dark', dark);
  }

}
