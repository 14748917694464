import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './core/auth/auth.service';
import { DeviceService } from './providers/device.service';
import { ResumeService } from './providers/resume.service';
import { ProfileService } from './providers/profile.service';
import { HourService } from './providers/hour.service';
import { GpsService } from './providers/gps.service';
export class AppComponent {
    constructor(storage, swUpdate, toastCtrl, authService, deviceService, resumeService, profileService, hourService, gps) {
        this.storage = storage;
        this.swUpdate = swUpdate;
        this.toastCtrl = toastCtrl;
        this.authService = authService;
        this.deviceService = deviceService;
        this.resumeService = resumeService;
        this.profileService = profileService;
        this.hourService = hourService;
        this.gps = gps;
        this.appPages = [
            {
                title: 'projects.title',
                url: '/projects',
                icon: 'calendar-outline'
            },
            {
                title: 'hours.title',
                url: '/hours',
                icon: 'time-outline'
            },
        ];
        this.loggedIn = false;
        this.dark = false;
        this.resumed = null;
        this.hourCount = 0;
        // needs to be called in the constructor, because before redirectTo-rule
        this.authService.runInitialLogin();
        this.version = environment.version;
        this.resumeService.resumed$.subscribe(() => {
            this.hourService.send().subscribe();
            this.resumed = new Date();
            // TODO: check stability from appref (check out docs)
            // this.swUpdate.checkForUpdate();
        });
        this.deviceService.setupDeviceId();
        this.hourService.hours$.subscribe(hours => {
            this.hourCount = hours.length;
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dark = yield this.storage.get('dark');
            this.currentYear = (new Date()).getFullYear();
            this.profileService.apiUrl$.pipe(filter(apiUrl => !!apiUrl)).subscribe(_ => {
                // Direkt nach start sobald ProfileService bzw. apiUrl ready ist
                this.hourService.send().subscribe();
            });
            this.authService.claims$.subscribe(claims => {
                if (claims) {
                    this.userName = claims.name;
                }
            });
            this.swUpdate.available.subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const toast = yield this.toastCtrl.create({
                    message: 'Update verfügbar!',
                    position: 'bottom',
                    buttons: [
                        {
                            role: 'cancel',
                            text: 'Aktualisieren'
                        }
                    ]
                });
                yield toast.present();
                toast
                    .onDidDismiss()
                    .then(() => this.swUpdate.activateUpdate())
                    .then(() => window.location.reload());
            }));
            this.gps.askPermission();
        });
    }
    logout() {
        this.authService.logout();
    }
    setDark(dark) {
        this.dark = dark;
        this.storage.set('dark', dark);
    }
}
