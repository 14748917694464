import { Subject } from 'rxjs';
import lifecycle from 'page-lifecycle';
import * as i0 from "@angular/core";
export class ResumeService {
    constructor() {
        this.resumeSubject$ = new Subject();
        this.resumed$ = this.resumeSubject$.asObservable();
        lifecycle.addEventListener('statechange', (event) => {
            // use active state for unfrozen from mobile browsers
            if (event.newState === 'active') {
                this.resumeSubject$.next();
            }
        });
        window.addEventListener('online', () => {
            this.resumeSubject$.next();
        });
    }
}
ResumeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResumeService_Factory() { return new ResumeService(); }, token: ResumeService, providedIn: "root" });
