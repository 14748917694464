import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth.service";
export class LoggedInGuard {
    constructor(authService) {
        this.authService = authService;
        this.authService.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
    }
    canActivate(route, state) {
        return this.authService.isDoneLoading$
            .pipe(filter(isDone => isDone), 
        // tap(() => console.log('loggedin guard: ', this.isAuthenticated)),
        tap(() => this.isAuthenticated || this.authService.login(state.url)), // Force Loging wenn nicht
        map(() => this.isAuthenticated));
    }
}
LoggedInGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInGuard_Factory() { return new LoggedInGuard(i0.ɵɵinject(i1.AuthService)); }, token: LoggedInGuard, providedIn: "root" });
